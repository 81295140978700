<template>
  <div class="catalog">

    <q-layout view="hhh lpr lfr" class="bg-grey-3">
      <q-header elevated class="bg-grey-4 text-black" height-hint="98">
        <q-toolbar class="justify-between">
          <div>
            <q-btn round flat icon="menu" @click="drawer = !drawer" />
          </div>      
          <div v-if="!isMobile" class="text-h4 text-bold text-italic">
            Catálogo de Vendas
          </div>
          <div v-else class="text-h6 text-bold text-italic">
            Catálogo de Vendas
          </div>
          <div>
            <q-btn round flat :icon="iconListGrid" @click="onListGrid" />
            <q-btn round flat icon="shopping_cart" @click="onCartShop">
              <q-badge rounded color="red" floating>
                {{ cartShopping.length }}
              </q-badge>
            </q-btn>
          </div>
        </q-toolbar>
      </q-header>

      <q-drawer
        v-model="drawer"
        show-if-above
        :width="200"
        :breakpoint="200"
        elevated
        class="bg-grey-4"
      >
        <q-scroll-area class="fit">
          <!-- <div class="q-my-md">
            <q-img
              :src="logoImg"
              width="120px"
            />
          </div> -->
          <q-list class="q-mt-lg q-gutter-y-xs">
            <template v-for="(menuItem, index) in categorias" :key="index">
              <q-item clickable v-ripple @click="onCategory(menuItem.categ)">
                <!-- <q-item-section avatar>
                  <q-icon name="fas fa-tshirt" size="sm" />
                </q-item-section> -->
                <q-item-section>
                  <div class="row">
                    <div class="col-11 text-subtitle2 text-bold">
                      {{ String(menuItem.categ).toUpperCase() }}
                    </div>
                    <div class="col-1">
                      <q-icon name="arrow_right" size="20px" />
                    </div>
                  </div>
                </q-item-section>
              </q-item>
              <!-- <q-separator :key="'sep' + index"  v-if="menuItem.separator" /> -->
            </template>
          </q-list>
        </q-scroll-area>
      </q-drawer>

      <q-page-container>
        <div class="flex row justify-between q-mt-md q-mb-sm q-mx-lg">
          <q-select
            outlined
            v-model="sortProducts"
            :options="optSort"
            dense
            options-dense
            style="width: 145px;"
            @blur="onSortProducts(prodsFilter)"
          >
            <template v-slot:prepend>
              <q-icon name="sort" />
            </template>
          </q-select>

          <q-input
            v-model="search"
            bg-color="grey-3"
            type="text"
            label="Pesquisar"
            style="width: 170px;"
            @change="filterProds(imagens)"
            outlined
            dense
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </div>
        <q-scroll-area style="height: calc(100vh - 130px)">
          <q-page padding v-if="iconListGrid !== 'grid_view'"> <!-- GRID VIEW -->
            <div class="row justify-center q-gutter-md">
              <q-card
                class="cardProduto bg-white text-black destaque cursor-pointer q-pa-xs"
                v-for="image in prodsFilter"
                :key="image"
                @click="onCard(image)"
              >
                <div class="q-mt-xs">
                  <q-chip square class="glossy text-caption" dense>
                    {{ image.brand }}
                  </q-chip>
                  <q-chip square class="glossy text-caption" dense>
                    {{ image.size }}
                  </q-chip>
                  <q-chip square class="glossy text-caption" dense>
                    {{ image.color }}
                  </q-chip>
                </div>
                <q-img
                  :src="image.url"
                  style="border-radius: 10px"
                  class="q-mt-sm"
                  width="120px"
                  height="120px"
                  spinner-color="bg-black"
                  spinner-size="82px"
                />
                <div>
                  <div class="text-body2">
                    {{ image.name }}
                  </div>
                  <div class="text-h6 text-bold q-mb-sm">
                    R$ {{ parseFloat(image.price).toLocaleString('pt-BR', { minimumFractionDigits: 2 }) }}
                  </div>
                </div>
              </q-card>
            </div>
          </q-page>

          <q-page padding v-else> <!-- LIST VIEW -->
            <div class="row justify-center q-gutter-md">
              <q-card
                class="cardProduto row items-center bg-white text-black destaque cursor-pointer"
                style="width: 95%"
                v-for="image in prodsFilter"
                :key="image"
                @click="onCard(image)"
              >
                <q-img
                  v-if="!isMobile"
                  :src="image.url"
                  style="border-radius: 10px;"
                  class="q-ma-sm"
                  width="110px"
                  height="110px"
                  spinner-color="bg-black"
                  spinner-size="75px"
                />
                <q-img
                  v-else
                  :src="image.url"
                  style="border-radius: 10px;"
                  class="q-ma-sm"
                  width="70px"
                  height="70px"
                  spinner-color="bg-black"
                  spinner-size="75px"
                />
                <div v-if="!isMobile" class="row items-center" style="width: calc(100% - 130px)">
                  <div class="col-9 q-gutter-y-md text-body2">
                    <div>{{ image.name }}</div>
                    <div>
                      <q-chip square class="glossy text-caption" dense>
                        {{ image.brand }}
                      </q-chip>
                      <q-chip square class="glossy text-caption" dense>
                        {{ image.size }}
                      </q-chip>
                      <q-chip square class="glossy text-caption" dense>
                        {{ image.color }}
                      </q-chip>
                    </div>
                  </div>
                  <div class="col-3 text-h6 text-bold q-mb-sm">
                    R$ {{ parseFloat(image.price).toLocaleString('pt-BR', { minimumFractionDigits: 2 }) }}
                  </div>
                </div>
                <div v-else class="row items-center" style="width: calc(100% - 90px)">
                  <div class="col-9 q-gutter-y-md text-body2">
                    <div>{{ image.name }}</div>
                    <div>
                      <q-chip square class="glossy text-caption" dense>
                        {{ image.brand }}
                      </q-chip>
                      <q-chip square class="glossy text-caption" dense>
                        {{ image.size }}
                      </q-chip>
                      <q-chip square class="glossy text-caption" dense>
                        {{ image.color }}
                      </q-chip>
                    </div>
                  </div>
                  <div class="col-3 text-subtitile1 text-bold q-mb-sm">
                    R$ {{ parseFloat(image.price).toLocaleString('pt-BR', { minimumFractionDigits: 2 }) }}
                  </div>
                </div>
              </q-card>
            </div>
          </q-page>
        </q-scroll-area>
      </q-page-container>
    </q-layout>

    <!-- MODAL CARDS -->
    <q-dialog v-model="modalCard" full-width>
      <div class="bg-white q-pa-lg q-gutter-y-sm">
        <div class="title text-h5 text-bold text-center q-mb-md">
          DETALHES DO PRODUTO
        </div>

        <q-separator spaced size="5px" />
        
        <!-- VISÃO MOBILE -->
        <q-scroll-area v-if="isMobile" style="width: 100%; height: 60vh;">
          <div class="q-gutter-y-sm column q-mt-sm">
            <div class="full-width col-6 row justify-center">
              <q-img
                :src="detCard.url"
                style="border-radius: 15px;"
                width="90%"
              />
            </div>
            <div class="col-6">
              <div class="text-center text-h4">
                {{ `${String(detCard.brand)}` }}
              </div>
              <div class="text-center text-h6">
                {{ `${String(detCard.name)}` }}
              </div>

              <q-separator spaced size="5px" />

              <div>
                <q-scroll-area style="width: 98%; height: 160px;">
                  <div v-html="detCard.infos" />
                </q-scroll-area>
              </div>

              <q-separator spaced size="5px" />

              <div class="q-mt-md row justify-center items-center q-gutter-x-md">
                <div class="text-h5 text-bold text-cinzaEscuro q-mr-lg">
                  {{ `R$ ${parseFloat(detCard.price).toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}` }}
                </div>
                <q-btn color="grey" icon="remove" @click="onRemove" style="width: 40px; height: 40px; border-radius: 50%" />
                <q-input
                  v-model="qtdade"
                  placeholder="0"
                  type="text"
                  label="Quantidade"
                  outlined
                  style="width: 150px"
                />
                <q-btn color="grey" icon="add" @click="onAdd" style="width: 40px; height: 40px; border-radius: 50%" />
              </div>

              <div class="row justify-center q-pt-xl">
                <q-btn outline no-caps color="negative" label="Cancelar" @click="modalCard = false" />
                <div class="q-mx-md" />
                <q-btn outline no-caps color="primary" label="Adicionar Carrinho" @click="onAddCart(detCard)" />
              </div>
            </div>
          </div>
        </q-scroll-area>

        <!-- VISÃO DESKTOP -->
        <q-scroll-area v-else style="width: 100%; height: 65vh;">
          <div class="q-gutter-y-sm row q-mt-sm">
            <div class="col-6 row justify-center">
              <q-img
                :src="detCard.url"
                style="border-radius: 15px;"
                height="400px"
                width="350px"
              />
            </div>
            <div class="col-6">
              <div class="text-center text-h4">
                {{ `${String(detCard.brand)}` }}
              </div>
              <div class="text-center text-h6">
                {{ `${String(detCard.name)}` }}
              </div>

              <q-separator spaced size="5px" />

              <div>
                <q-scroll-area style="width: 98%; height: 160px;">
                  <div v-html="detCard.infos" />
                </q-scroll-area>
              </div>

              <q-separator spaced size="5px" />

              <div class="q-mt-md row justify-center items-center q-gutter-x-md">
                <div class="text-h5 text-bold text-cinzaEscuro q-mr-lg">
                  {{ `R$ ${parseFloat(detCard.price).toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}` }}
                </div>
                <q-btn color="grey" icon="remove" @click="onRemove" style="width: 40px; height: 40px; border-radius: 50%" />
                <q-input
                  v-model="qtdade"
                  placeholder="0"
                  type="text"
                  label="Quantidade"
                  outlined
                  style="width: 150px"
                />
                <q-btn color="grey" icon="add" @click="onAdd" style="width: 40px; height: 40px; border-radius: 50%" />
              </div>

              <div class="row justify-center q-pt-md">
                <q-btn outline no-caps color="negative" label="Cancelar" @click="modalCard = false" />
                <div class="q-mx-md" />
                <q-btn outline caps color="primary" label="Adicionar ao Carrinho" @click="onAddCart(detCard)" />
              </div>
            </div>
          </div>
        </q-scroll-area>
      </div>
    </q-dialog>

    <!-- MODAL CART SHOPPING -->
    <q-dialog v-model="modalCartShop" full-width persistent>
      <div class="bg-white q-pa-lg q-gutter-y-sm">
        <div class="titlebar row items-center justify-between q-mb-md">
          <div class="title text-h6 text-bold text-center q-ml-lg">
            CARRINHO DE COMPRA
          </div>
          <div>
            <q-btn class="q-px-sm" color="negative" label="X" @click="onCloseModalCartShopping" />
          </div>
        </div>

        <q-separator spaced size="5px" />
        
        <!-- VISÃO MOBILE -->
          <div v-if="isMobile" class="q-gutter-y-sm q-mt-sm">
            <div class="col-6 justify-center">
              <div class="text-h6 row justify-center">
                <div class="items-center">
                  <div class="text-bold">Lista de Compra</div>
                  <q-btn class="full-width text-red" flat no-caps label="Limpar Carrinho" @click="onClearCartShopping" />
                </div>
              </div>
              <div class="row">
                <!-- <q-scroll-area style="width: 100%;"> -->
                  <div class="gradeTable full-width row justify-center items-center q-my-sm q-pr-lg" v-for="(cart, index) in cartShopping" :key="index">
                    <q-img
                      :src="cart.url"
                      class="q-mt-sm"
                      width="60px"
                      height="60px"
                    />
                    <div class="column items-center">
                      <div class="text-subtitle1 text-center">
                        {{ `${cart.product} (${cart.brand}): ${cart.color} - ${cart.size} x ${cart.qtdade}` }}
                      </div>

                      <div>
                        <q-btn round flat color="red" icon="highlight_off" @click="removeItem(index)" />
                      </div>
                    </div>
                  </div>
                <!-- </q-scroll-area> -->
              </div>
            </div>
            <div class="col-6">
              <div class="text-h6 text-center q-ml-lg">
                Cadastro do Cliente
              </div>
              <div class="q-gutter-y-md q-mt-md q-px-lg">
                <q-input outlined v-model="client" type="text" label="Nome Completo" />
                <q-input outlined v-model="cep" type="text" label="CEP" />
                <q-input outlined v-model="address" type="text" label="Endereço" />
                <q-input outlined v-model="phone" type="text" label="Celular" />
              </div>
            
              <div class="row justify-center">
                <q-btn class="q-mt-md" outline caps color="primary" label="Solicitar Orçamento" @click="onSentOrder" />
              </div>
            </div>
          </div>

        <!-- VISÃO DESKTOP -->
          <div v-else class="q-gutter-y-sm row q-mt-sm">
            <div class="col-6 justify-center">
              <div class="text-h6 row justify-center q-ml-lg">
                <div class="items-center">
                  <div class="text-bold">Lista de Compra</div>
                  <q-btn class="full-width text-red" flat no-caps label="Limpar Carrinho" @click="onClearCartShopping" />
                </div>
              </div>
              <div class="row">
                <q-scroll-area style="width: 100%; height: 50vh;">
                  <div class="full-width row justify-between items-center q-my-sm q-pr-lg" v-for="(cart, index) in cartShopping" :key="index">
                    <q-img
                      :src="cart.url"
                      width="60px"
                      height="60px"
                    />

                    <div class="text-subtitle2 q-ml-sm">
                      {{ description(cart) }}
                      <!-- {{ String(`${cart.qtdade} x ${cart.product}: (${cart.color} - ${cart.size})`).substr(0, 50) +  '...' }} -->
                    </div>

                    <div class="justify-right">
                      <q-btn round flat color="red" icon="highlight_off" @click="removeItem(index)" />
                    </div>
                  </div>
                </q-scroll-area>
              </div>
            </div>
            <div class="col-6">
              <div class="text-h6 text-center q-ml-lg">
                Cadastro do Cliente
              </div>
              <div class="q-gutter-y-md q-mt-md q-px-lg">
                <q-input outlined v-model="client" type="text" label="Nome Completo" />
                <q-input outlined v-model="cep" type="text" label="CEP" />
                <q-input outlined v-model="address" type="text" label="Endereço" />
                <q-input outlined v-model="phone" type="text" label="Celular" />
              </div>
            
              <div class="row justify-center">
                <q-btn class="q-mt-md" outline caps color="primary" label="Enviar Pedido" @click="onSentOrder" />
              </div>
            </div>
          </div>

      </div>
    </q-dialog>
  </div>
</template>

<script>
import { supabase } from '../../supabase';
import { Dialog } from 'quasar';
import DateNow from '../../helpers/dateNow';

export default {
  data() {
    return {
      drawer: true,
      categorias: [],
      imagens: [],
      prodsFilter: [],
      detCard: [],
      modalCard: false,
      category: null,
      color: null,
      size: null,
      qtdade: 0,
      cartShopping: [],
      modalCartShop: false,
      client: null,
      cep: null,
      address: null,
      phone: null,
      logoImg: null,
      iconListGrid: null,
      search: null,
      sortProducts: 'Classificar',
      optSort: ['Maior Valor', 'Menor Valor'],
    }
  },
  computed: {
    isMobile() {
      return (this.$q.screen.sm || this.$q.screen.xs);
    },
  },
  methods: {
    description(cart) {
      const desc = `${cart.qtdade} x ${cart.product}: (${cart.color} - ${cart.size})`
      const len = String(desc).length
      
      if (len > 50) {
        return String(desc).substr(0, 50) +  '...'
      } else {
        return desc
      }
    },
    onSortProducts(elements) {
      if(this.sortProducts === 'Maior Valor') {
        elements.sort((a, b) => parseFloat(b.price) - parseFloat(a.price))
      } else {
        elements.sort((a, b) => parseFloat(a.price) - parseFloat(b.price))
      }

      this.prodsFilter = elements;
    },
    filterProds(elements) {
      const newData = []
      elements.filter(elem => {
        if(String(String(elem.name).toUpperCase()).includes(String(this.search).toUpperCase())) {
          newData.push(elem)
        }
      })

      this.prodsFilter = newData;
    },
    onListGrid() {
      if(this.iconListGrid === 'grid_view') {
        this.iconListGrid = 'list_view'
      } else {
        this.iconListGrid = 'grid_view'
      }
    },
    async onCategory(category) {
      const slug = this.$route.params.slug
      this.imagens = []

      const { data } = await supabase
        .from('GE_CATALOG')
        .select('*')
        .neq('DELETED', '*')
        .match({
          GE_SLUG: slug,
          GE_CATEGORY: category,
        })

        // let imgs = []
        data.map(async img => {
          if(img.GE_ACTIVE) {
            const {data} = await supabase
              .storage
              .from('catalog')
              .getPublicUrl(img.GE_AVATAR)
          
            this.imagens.push({
              url: data.publicURL,
              name: img.GE_PRODUCT,
              category: img.GE_CATEGORY,
              // price: parseFloat(img.GE_PRICE).toLocaleString('pt-BR', { minimumFractionDigits: 2 }),
              price: img.GE_PRICE,
              color: img.GE_COLOR,
              brand: img.GE_BRAND,
              size: img.GE_SIZE,
              recno: img.RECNO,
              infos: img.GE_INFOS,
              slug: img.GE_SLUG
            })
          }
        })

      this.prodsFilter = this.imagens;

      if(this.isMobile) {
        this.drawer = false;
      } else {
        this.drawer = true;
      }
    },
    onCard(infos) {
      this.resetFormItens();
      this.modalCard = true;
      this.detCard = infos;
    },
    onAddCart(data) {
      if(!this.validFormItens()) {
        return ''
      }

      let infos = {
        url: data.url,
        category: data.category,
        product: data.name,
        brand: data.brand,
        color: data.color,
        size: data.size,
        qtdade: this.qtdade,
        slug: data.slug,
        price: data.price
      }

      this.cartShopping.push(infos)

      this.resetFormItens();
      this.modalCard = false;
    },
    resetFormItens() {
      this.qtdade = 0
    },
    validFormItens() {

      if(this.qtdade <= 0) {
        Dialog.create({
          title: 'Erro ao informar a Quantidade',
          message: 'Favor informar a QUANTIDADE desejada.'
        })

        return false;
      }

      return true;

    },
    onCartShop() {
      this.modalCartShop = true;
      console.log(this.cartShopping);
    },
    validFormClient() {
      if(this.client == null || this.client == '') {
        Dialog.create({
          title: 'Erro Cliente',
          message: 'Favor informar o nome do cliente completo.'
        })

        return false;
      }
      
      if(this.address == null || this.address == '') {
        Dialog.create({
          title: 'Erro Endereço',
          message: 'Favor informar o endereço completo.'
        })

        return false;
      }
      
      if(this.phone == null || this.phone == '') {
        Dialog.create({
          title: 'Erro Celular',
          message: 'Favor informar o Celular com DDD.'
        })

        return false;
      }
      
      if(this.cartShopping.length <= 0) {
        Dialog.create({
          title: 'Carrinho Vazio',
          message: 'Favor selecionar os PRODUTOS que deseja.'
        })

        return false;
      }

      return true;

    },
    async onSentOrder() {
      if(!this.validFormClient()) {
        return ''
      }

      const enterprise = this.$route.params.slug
      const dt = DateNow(Date(Date.now))

      const { data, error } = await supabase
        .from('GE_PRE_SALE')
        .insert({
          GE_CLIENT: this.client,
          GE_TEAM_HEARTH: this.cep,
          GE_ADDRESS: this.address,
          GE_PHONE: this.phone,
          GE_ITEMS: this.cartShopping,
          GE_STATUS: 'PENDENTE',
          GE_ENTERPRISE: enterprise,
          GE_DATE_ISSUE: dt,
          GE_LOGO: this.logoImg,
          DELETED: ' '
        })

      if(error) { console.error(error.message) }
      if(data) {
        this.modalCartShop = false;
        this.cartShopping.splice(0, this.cartShopping.length);

        Dialog.create({
          title: `<p class="text-h6 text-bold">Obrigado pela preferência</p>`,
          message: '<p>Seu pedido de orçamento foi recebido com sucesso! Em breve um de nossos vendedores entrará em contato.<br><br>Obrigado!!!</p>',
          persistent: true,
          html:true,
          cancel: false,
          ok: 'OK'
        })
      }
    },
    onCloseModalCartShopping() {
      this.modalCartShop = false;

      //Verificar se há necessidade de resetar formulário!!!
    },
    removeItem(index) {
      Dialog.create({
        title: '<p class="text-h6 text-bold">Removendo Item do Carrinho</p>',
        message: '<p>Deseja realmente <b class="text-red">REMOVER</b> este item?</p>',
        persistent: true,
        html:true,
        cancel: "Não",
        ok: "Sim"
      }).onOk(() => {
        this.cartShopping.splice(index, 1)
      })
    },
    onClearCartShopping() {
      Dialog.create({
        title: '<p class="text-h6 text-bold">Limpar Carrinho de Compra</p>',
        message: '<p>Deseja realmente <b class="text-red">REMOVER</b> todos os itens?</p>',
        persistent: true,
        html:true,
        cancel: 'Não',
        ok: 'Sim'
      }).onOk(() => {
        this.cartShopping.splice(0, this.cartShopping.length)
      })
    },
    onAdd() {
      this.qtdade += 1
    },
    onRemove() {
      if(this.qtdade === 0)
        return

      this.qtdade -= 1
    }
  },
  async created() {
    this.iconListGrid = 'grid_view'
    const enterprise = this.$route.params.slug

    const { data: branch } = await supabase
      .from('GE_SETTINGS')
      .select('GE_ENTERPRISE')
      .match({
        GE_SLUG: enterprise
      })

    const { data: categorias } = await supabase
      .rpc(
        'categorias_catalogo',
        {
          enterprise: branch[0].GE_ENTERPRISE
        }
      )

    this.categorias = categorias

    //~>Carregando a Logo
    const logo = await supabase
      .storage
      .from('logos')
      .getPublicUrl(enterprise + '.png')

    this.logoImg = logo.data.publicURL

  }
}
</script>

<style lang="scss">
  body {
    overflow: hidden;

    .catalog {
      
      .category {
        height: 100%;
        border-right: 1px solid gray;
      }

      .cardProduto {
        width: 165px;
      }

      .destaque {
        box-shadow: 2px 2px 7px rgb(99, 98, 98);
      }
    }

    .text-cinzaEscuro {
      color: #686868;
    }
  }
</style>